/* NAVBAR */

/* Color of the links BEFORE scroll */
.navbar-scroll .nav-link,
.navbar-scroll .navbar-toggler-icon,
.navbar-scroll .navbar-brand {
  color: #fff;
}

/* Color of the links AFTER scroll */
.navbar-scrolled .nav-link,
.navbar-scrolled .navbar-toggler-icon,
.navbar-scrolled .navbar-brand {
  color: #fff;
}

/* Color of the navbar AFTER scroll */
.navbar-scroll,
.navbar-scrolled {
  background-color: #cbbcb1;
}

.mask-custom {
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.15);
}

.navbar-brand {
  font-size: 1.75rem;
  letter-spacing: 3px;
}

/* Patners */
.partner-logo {
  max-height: 150px; /* Adjust this value as needed for uniformity */
  width: auto;
  max-width: 100%;
  padding: 20px;
  transition: transform 0.2s ease-in-out;
}

.partner-logo:hover {
  transform: scale(1.1);
}

/* Illustrations */
.card {
  transition: transform 0.2s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
}
